<template>
  <div class="Userhl_favideohj" style="margin-top:4rem;">
    <div class="fxiangmu_top">
      <p class="videohejititle">新建合集</p>
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">合集名称</div>
          <div class="fxiangmu_toplir">
            <el-input v-model="hj_name" placeholder="请输入合集名称"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">设计专业</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="zhuanye" placeholder="请选择设计专业">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">上传封面</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn videofengmian">
            <img :src="fmimg" alt="" />
            <el-button plain icon="el-icon-picture">
              上传封面
              <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">付费类型</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="radio" label="2">收费</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="radio" label="1">免费</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox fxiangmu_toplir_weituoboxphone" v-if="hejimoney_t">费用</div>
            <div class="fxiangmu_toplir_weituobox" v-if="hejimoney_t">
              <el-input v-model="hejimoney" placeholder="请输入费用"></el-input>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">合集描述</div>
          <div class="fxiangmu_toplir">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入合集描述"
              v-model="hejitextarea"
            ></el-input>
          </div>
        </li>
      </ul>
      <el-button class="xiangmusub_btn" type="primary" plain @click="subheji"
        >新建合集</el-button
      >
    </div>
  </div>
</template>
<script>
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: { Uploadimg },
  data() {
    return {
      imgalls: {
        name: "",
        width:274,
        height:168,
      },
      options: [
        {
          value: "22",
          label: "环境设计",
        },
        {
          value: "23",
          label: "产品设计",
        },
        {
          value: "24",
          label: "视觉传达设计",
        },
      ],
      ////////////   新建合集       ////////////////////
      hj_name: "", //合集名字
      zhuanye: "", //设计专业
      radio: "2", //是否收费
      hejimoney: "", //合计费用
      hejimoney_t: true,
      hejitextarea: "", //合计描述
      ////////////   新建合集完成       ////////////////////
      fmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //封面图   公共
      userinfo :{},
    };
  },
  methods: {
    uploadimgurl(rul) {
      this.fmimg = rul;
    },
    subheji() {
      var that = this;
      this.$axios({
headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url:"/api/getPosterAdd",
        method: "post",
        data: {
          title: that.hj_name,
          p_type: that.zhuanye,
          title_pic: that.fmimg,
          drawtext: that.hejitextarea,
          charge: that.radio,
          money: that.hejimoney,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.$message(res.data.messages);
          that.$router.push({ path: "/Userhl_favideo" });
        }
      });
    },
  },
  watch: {
    radio: function () {
      if (this.radio == "2") {
        this.hejimoney_t = true;
      } else {
        this.hejimoney_t = false;
      }
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
  },
};
</script>
<style>
@import "../../assets/css/Sjfabu.css";
</style>
